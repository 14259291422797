import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {
  onMounted,
  ref,
  computed,
  onUnmounted,
  watchEffect,
  reactive,
  nextTick,
  watch,
} from "vue";
import axios from "axios";
import * as echarts from "echarts";

export default {
  props: ["keyWord"],
  setup(__props) {

const props = __props


const proportion = ref(null);
const data = ref([]); // 图表的数据
let myChart1 = null; // 图标实例
const titleArr11 = ref([]);
watch(
  () => props.keyWord,
  (val) => {
    nextTick(() => {
      onKeyChange(val);
    });
  },
  { immediate: true }
);

// 分成五份 每一份都自己处理
const handlerData = (arr = []) => {
  // 先获取 title 日期
  const titleArr = arr[0].trend.map((item) => {
    return item.set_time;
  });
  titleArr11.value = titleArr;
  return arr.map((v, index) => {
    const data = v.trend.map((itme) => itme.price);
    return {
      name: v.region_title,
      id: v.id,
      type: "line",
      data,
      symbolSize: 0,
    };
  });
};

const onKeyChange = async (key) => {
  myChart1.showLoading();
  // 请求数据
  // let res = await axios.post("http://demo.zhengyongmei.top/api/test/trend");
  // const temp = handlerData(res.data.data.trend);
  setTimeout(() => {
    const temp = [
      {
        name: "螺纹钢",
        id: new Date(),
        type: "line",
        data: 123,
        symbolSize: 0,
      },
      {
        name: "线钢",
        id: new Date(),
        type: "line",
        data: 123123,
        symbolSize: 0,
      },
      {
        name: "螺纹钢233",
        id: new Date(),
        type: "line",
        data: 1234,
        symbolSize: 0,
      },
      {
        name: "螺纹钢",
        id: new Date(),
        type: "line",
        data: 123,
        symbolSize: 0,
      },
      {
        name: "螺纹钢",
        id: new Date(),
        type: "line",
        data: 123,
        symbolSize: 0,
      },
      {
        name: "螺纹钢",
        id: new Date(),
        type: "line",
        data: 123,
        symbolSize: 0,
      },
      {
        name: "螺纹钢",
        id: new Date(),
        type: "line",
        data: 123,
        symbolSize: 0,
      },
    ];
    data.value = temp;
    myChart1.hideLoading();
    myChart1.setOption(options.value);
  }, 1000);
};

// 图表的配置项
const options = computed(() => {
  return {
    tooltip: {
      trigger: "axis",
      bottom: -80,
    },
    legend: {
      bottom: 0,
      tooltip: {
        show: true,
      },
    },

    /* 控制视图的比例 */
    grid: {
      left: "4%",
      bottom: "10%",
      containLabel: true,
    },

    title: {
      text: "螺纹钢HRB400 20MM价格走势图",
    },
    xAxis: {
      type: "category",
      nameLocation: "middle",
      data: titleArr11.value,
      // data: ["2022-7-24", "2022-7-25", "2022-7-26", "2022-7-27", "2022-7-28"],
    },
    yAxis: {
      min: function (value) {
        return parseInt(value.min);
      },
      splitNumber: 10,
    },
    series: [...data.value],
  };
});

// 初始化时
onMounted(() => {
  myChart1 = echarts.init(proportion.value);
  myChart1.setOption(options.value);
  myChart1.showLoading();
  nextTick(() => {
    window.addEventListener("resize", echartResize);
  });
});

const echartResize = () => {
  myChart1.resize();
};

onUnmounted(() => {
  window.removeEventListener("resize", echartResize);
});

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "proportion",
    ref_key: "proportion",
    ref: proportion
  }, null, 512))
}
}

}